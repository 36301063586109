import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import styled from "styled-components"

const StyledDivContent = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 307px;
  height: 143px;

  @media (max-width: 1023px) {
    width: 304px;
    height: 95px;
  }
`

const HeroHomeWithImageBackground = () => {
  const data = useStaticQuery(graphql`
    query HeroHomeWithImageBackgroundQuery {  
      prismicHomePage {
        data {
          hero_subtitle
          button_name_1
          button_name_1_hover
          button_link_1 {
            uid
          }
          button_name_2
          button_link_2 {
            uid
          }
          hero_logo {
            gatsbyImageData(
              width: 307
            )
          }
          hero_background_image {
            gatsbyImageData(
              width: 1920
              height: 860
              layout: FULL_WIDTH
              placeholder: NONE
            )
          }
        }
      }         
      imageBg: file(relativePath: { eq: "backgrounds/home-hero-bg-new.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 860
            formats: [WEBP]
          )
        }
      }
    }
  `)
  
  const doc = data.prismicHomePage
  const imageBg = getSrc(data.imageBg)
  const heroLogo = getImage(doc.data.hero_logo)

  const StyledDiv = styled.div`
    width: auto;
    height: 100vh;
    background-image: url(${imageBg}); /* The image used */
    background-color: #423E42 /* Used if the image is unavailable */
    /* height: 500px;  You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    opacity: 70%;
    }
  `

  return (
    <div>
      <Helmet>
        <link rel="preload" as="image" href={imageBg} />
      </Helmet>

      <StyledDiv className="relative"></StyledDiv>          

      <StyledDivContent className="absolute top-0 bottom-0 left-0 right-0 w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 my-auto mx-auto">
        <div className="flex justify-center">
          <StyledGatsbyImage
            image={heroLogo}
            alt="SRA 831(b) ADMIN logo"
            objectFit="contain"
            formats={["auto", "webp", "avif"]}
            loading="eager"
            placeholder="none"
            className="w-2/3 md:w-full mx-auto"
          />
        </div>

        <h2 className="text-white text-5xl md:text-6xl font-black text-center text-shadow uppercase mt-4">
          Weather The Storm<sup>&reg;</sup>
        </h2>

        <h3 className="max-w-xl text-white text-xl md:text-2xl font-bold text-center text-shadow mt-6">
          {doc.data.hero_subtitle}
        </h3>

        <div className="flex flex-col items-center justify-center mt-6">
          <div className="hidden xl:flex flex-col items-center justify-center">
            <Link to={doc.data.button_link_1.uid ? `/${doc.data.button_link_1.uid}` : "/"}>
              <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3 mb-6">
                <span className="default-text mx-2">{doc.data.button_name_1}</span>
                <span className="hover-text">{doc.data.button_name_1_hover}</span>
              </button>
            </Link>
          </div>

          <div className="block xl:hidden mb-6">
            <p className="text-white text-2xl font-semibold text-center text-shadow tracking-wider mb-6">Do You Qualify?</p>
            <Link to="/qualify">
              <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                {doc.data.button_name_1_hover}
              </button>
            </Link>
          </div>

          <Link to={doc.data.button_link_2.uid ? `/${doc.data.button_link_2.uid}` : "/"}>
            <button id="testimonials-button" className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-9 py-3">
              {doc.data.button_name_2}
            </button>
          </Link>
        </div>            
      </StyledDivContent>
    </div>
  )
}

export default HeroHomeWithImageBackground

export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([    
    <script
      key="custom-script"
      dangerouslySetInnerHTML={{
        __html: `
          window.addEventListener('load', (event) => {
            document.getElementById('qualify-button').addEventListener('click', function() {
              window.cg_conversion = true;
            });

            document.getElementById('testimonials-button').addEventListener('click', function() {
              window.cg_conversion = true;
            });
          });
        `,
      }}
    />,
  ]);
};